import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const notification = (state: RootState) => state.notifications || initialState;

const selectSlice = (state: RootState) => state.app || initialState;

export const selectApp = createSelector([selectSlice], state => state);

export const selectNotifications = createSelector(
  [notification],
  state => state,
);

export const selectIsLoading = createSelector(
  [selectApp],
  state => state.loading,
);

export const selectIsSuccess = createSelector(
  [selectApp],
  state => state.success,
);

export const selectIsError = createSelector([selectApp], state => state.error);

export const selectIsLogged = createSelector(
  [selectApp],
  state => state.isLogged,
);

export const selectIsShowDialogLogin = createSelector(
  [selectApp],
  state => state.isShowDialogLogin,
);

export const selectIsShowDialogRegister = createSelector(
  [selectApp],
  state => state.isShowDialogRegister,
);

export const selectIsShowDialogForgetPassword = createSelector(
  [selectApp],
  state => state.isShowDialogForgetPassword,
);

export const selectLayout = createSelector([selectApp], state => state.layout);

export const selectLayoutHeader = createSelector(
  [selectLayout],
  layout => layout?.header,
);

export const selectLayoutFooter = createSelector(
  [selectLayout],
  layout => layout?.footer,
);

export const selectInfoUser = createSelector(
  [selectApp],
  state => state.infoUser,
);

export const selectListNotification = createSelector(
  [selectApp],
  state => state.notifications,
);

export const selectCountListNotificationUnread = createSelector(
  [selectListNotification],
  list => list.filter(item => !item.seen).length,
);
