import { objectType } from 'types';
import { BaseXHR } from 'utils/axios';

export const updateAvatarApi = (data: FormData) =>
  BaseXHR.$post('v1/account/update-avatar', data);

export const changePasswordApi = (data: FormData) =>
  BaseXHR.$post('v1/account/change-password', data);

export const updateInfoUserApi = (data: FormData) =>
  BaseXHR.$post('v1/account/update', data);

export const toggleLikeProductApi = (id: number) =>
  BaseXHR.$post(`v1/account/react/${id}`);

export const getListOrder = (payload?: objectType) =>
  BaseXHR.$get(`v1/account/order/listing`, payload);

export const logoutApi = () => BaseXHR.$post('v1/account/logout');
