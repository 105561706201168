import styled, { createGlobalStyle, css } from 'styled-components';

export const Flex = css`
  display: flex;
`;

export const FlexCol = css`
  display: flex;
  flex-direction: column;
`;

export const FlexRowCenter = css`
  ${Flex};
  justify-content: center;
  align-items: center;
`;

export const FlexRowStart = css`
  ${Flex};
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowEnd = css`
  ${Flex};
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRowBetween = css`
  ${Flex}
  justify-content: space-between;
  align-items: center;
`;

export const FlexRowAround = css`
  ${Flex}
  justify-content: space-around;
  align-items: center;
`;

export const FlexColCenter = css`
  ${FlexCol};
  ${FlexRowCenter}
`;

export const FlexColStart = css`
  ${FlexCol};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FlexColEnd = css`
  ${FlexCol};
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FlexColBetween = css`
  ${FlexCol};
  justify-content: space-between;
  align-items: baseline;
`;

export const BackgroundCover = css<{ bgUrl: string | null | undefined }>`
  background-image: url(${props => props.bgUrl && props.bgUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackgroundContain = css`
  ${BackgroundCover};
  background-size: contain;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${FlexRowCenter};
`;

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  :root {
    --color-1: #006DB7;
    --color-2: #9659E4;
    --color-3: #FCE9E6;
    --color-4: #F35B04;
    --color-5: #A7A7A7;
    --color-6: #898989;
  }

  .el-hide {
    display: none !important;
  }

  ul {
    list-style: none;
  }

  .--link {
    /* color: var(--color-1) !important; */
    cursor: pointer;
    i {
      margin-right: 6px;
    }
  }

  .breadcrumb {
    padding: 6px 12px;
    background: #F1F1F1;
    width: max-content;
    border-radius: 6px;

    .--link {
      color: #000;
    }

    .--item {
      &.active {
        color: var(--color-1);
      }
    }
  }

  .--link {
    cursor: pointer;
  }


  body {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    background: #F6F6F6;

    .ant-checkbox {
      input {
      }
      + span {
        display: inline-flex;
        align-items: center;
        margin-top: 4px;
        margin-left: 4px;
      }
      &-inner {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background: #fff;
        border: 1px solid #DDDDDD;
        &::after {
          position: absolute;
          top: 45%;
          left: 24.5%;
        }
      }
      &-checked {
        .ant-checkbox-inner {
          background: var(--color-1);
          color: #FFF;
          border: 1px solid var(--color-1);
        }
      }
      &-wrapper {
        color: #000;
      }
    }

    .ant-select-dropdown {
      z-index: 10500000;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }

  a {
    text-decoration: none;
  }

  .btn_1 {
    background: rgba(0, 109, 183, 0.05) !important;
    border: 1px solid var(--color-1) !important;
    border-radius: 6px !important;
    padding: 0 20px !important;
    height: 40px !important;
    color: var(--color-1) !important;
    transition: all .3s ease;
    &:hover{
      background: var(--color-1) !important;
      color: #FFF !important;
    }
  }

  .btn_2 {
    background: var(--color-1) !important;
    padding: 0 20px !important;
    border-radius: 6px !important;
    color: #FFF !important;
    height: 40px !important;;
    border: 1px solid var(--color-1) !important;
    transition: all .3s ease;
    &.error {
      background: rgba(224, 28, 28, 1) !important;
      border: 1px solid rgba(224, 28, 28, 1) !important;
    }
    &:hover{
      background: rgba(0, 109, 183, 0.05) !important;
      color: var(--color-1) !important;
    }
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    box-shadow: ;
    width: 100%;
    @media (max-width: 768px) {
      // display: none;
    }
  }

  .humbeger {
    @media (max-width: 768px) {
      display: flex !important;
      flex-flow: column;
      &.active{
        p{
          margin-bottom: 0;
          width: 29px;
          transition: all .3s ease;
          &:nth-child(1){
            transform: rotate(45deg) translate(3px,0);
          }
          &:nth-child(2){
            width: 0;
          }
          &:nth-child(3){
            transform: rotate(-45deg) translate(1px,0);
          }
        }
      }
      p {
        width: 32px;
        height: 2px;
        background: var(--color-1);
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .header {
    @media (max-width: 768px) {
      .search_mobi {
        display: block !important;
        margin-top: 1rem;
      }
    }
  }

  .header_top {
    background: var(--color-1);

    .--contact {
      display: flex;
      margin-bottom: 0;
      padding-left: 0;
      font-weight: 500;

      li {
        &:nth-child(1) {
          margin-right: 2rem;
        }
      }

      .bttn {
        color: #FFF;
      }
    }

    .menu {
      .--item {
        padding: 0 8px;
        color: #FFF;
        position: relative;
        font-weight: 500;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          height: 14px;
          transform: translate(0, -50%);
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }

    @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      height: calc(100vh - 110px);
      width: 100%;
      z-index: 9;
      opacity: 0;
      transform: translate(-150px, 0);
      transition: all .5s ease;
      pointer-events: none;

      .--contact {
        display: none;
      }

      &.active {
        opacity: 1;
        transform: none;
        pointer-events: auto;
      }

      .--content {
        height: 100%;
      }

      .container {
        height: 100%;
      }

      .menu {
        flex-flow: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        .--item {
          font-size: 24px;
          margin-bottom: 1rem;
          width: 100%;
          text-align: center;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  .header_bot {
    padding: 20px 0;
    background: #FFF;
    .--account{
      position: relative;
    }
    .--logo {
      img {
        max-width: 180px;
        height: 100%;
        max-height: 40px;
      }
    }
    .--cate {
      margin-left: 2rem;
      position: relative;
      span {
        background: none;
        color: var(--color-1);
        border: none;
        font-weight: 600;
        &:focus{
          box-shadow: none;
        }
        i {
          font-size: 14px;
          margin-left: 8px;
        }
      }
      .list_cate {
        position: absolute;
        z-index: 9;
        top: 100%;
        left: 50%;
        transform: translate(-50%,0);
        width: 100%;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        .card {
          border: none;
          padding: 0 12px;
          .--item {
            border-bottom:1px solid var(--color-5) ;
            padding: 8px 0;
            &:hover {
              color: var(--color-1);
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    .--avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 1.5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .cart {
      margin-left: 1.5rem;
    }

    .info {
      border: none;
      background: none;
      position: relative;

      span {
        font-size: 12px;
        background: var(--color-4);
        color: #FFF;
        border-radius: 50%;
        border: 3px solid #FFF;
        display: flex;
        height: 20px;
        width: 20px;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: -5px;
        display: none;
      }

      &.active {
        span {
          display: flex;
        }
      }
    }

    .btnRegis {
      margin-left: 1.5rem;

      i {
        display: none;
      }
    }

    .search {
      position: relative;

      input {
        width: 230px;
        border: 1px solid #D1D1D1;
        border-radius: 100px;
        height: 40px;
        padding-left: 2rem;
        color: var(--color-5);

        &::placeholder {
          color: var(--color-5);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 0.5px var(--color-1);
        }

        &:focus-visible {
          outline: none;
        }
      }

      i {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 10px;
        color: var(--color-5);
      }
    }

    @media (max-width: 768px) {
      height: 110px;
      padding: 0;
      display: flex;
      align-items: center;
      position: fixed;
      border-bottom:1px solid var(--color-1) ;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9;
      .--cate {
        display: none;
      }

      .btnRegis {
        margin-left: 1rem;

        i {
          display: block;
        }

        span {
          display: none;
        }

        button {
          padding: 0 8px !important;
          height: 32px !important;
        }
      }

      .--right {
        .search {
          display: none;
        }
      }

      .--account {
        margin-left: .5rem !important;
        .--avatar{
          margin-left: .5rem;
        }
      }

      .--logo {
        margin-left: 1rem;

        img {
          width: 120px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .notification{
      width: 100vw;
      transform: none;
    }
  }

  .notification {
    left: 0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    background: #FFF;
    width: 320px;
    max-height: 60vh;
    height: max-content;
    overflow-y: auto;
    transition: all .3s ease;
    ::-webkit-scrollbar-track {
      background: #FFF;
    }
    .--title{
      margin:8px 16px;
      .--viewall{
        color: var(--color-1);
      }
    }
    &.active{
      opacity: 1;
      pointer-events: auto;
    }
    .--item{
      position: relative;
      &:last-child{
        &::after{
          border-bottom:0 ;
        }
      }
      &.--seen {
        background: linear-gradient(0deg,rgba(0,109,183,0.05),rgba(0,109,183,0.05)),#FFFFFF;
        border-radius: 6px;
      }
      &::after{
        content: '';
        left: 0;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom:1px solid var(--color-1) ;
      }
    }
  }

  .footer {
    background: #003049;
  }

  .footer_top {
    padding: 2rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.33);

    .--logo {
      img {
        width: 180px;
      }
    }

    .--contact {
      li {
        display: flex;
        align-items: center;

        &:nth-child(1) {
          margin-right: 2rem;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: calc(100% + 1rem);
            height: 1rem;
            border-left: 1px solid rgb(255 255 255 / 20%);
          }
        }
      }

      a {
        color: #FFF;
        transition: all .2s ease;

        i {
          color: rgba(255, 255, 255, 0.5);
          margin-right: 12px;
          transition: all .2s ease;
        }

        &:hover {
          color: var(--color-1);

          i {
            color: var(--color-1);
          }
        }
      }
    }

    @media (max-width: 768px) {
      .--content {
        flex-flow: column;
        align-items: flex-start !important;

        ul {
          flex-flow: column;
          margin-top: 1rem;
        }
      }

      .--contact {
        li {
          &:nth-child(1)::after {
            display: none;
          }
        }
      }
    }
  }

  .footer_bot {
    color: #FFF;

    .--content {
      padding: 24px 0 48px;
    }

    .--left {
      width: 30%;

    }

    .--right {
      width: 35%;
    }

    h6 {
      font-size: 18px;
      margin-bottom: 12px;
      color: #FFF;
    }

    article {
      opacity: .5;
      font-size: 12px;
      margin-bottom: 2rem;
    }

    .--address {
      svg {
        margin-right: 1rem;
      }
    }

    .footer_link {
      span {
        margin-bottom: 18px;
      }
      .--link {
        margin-bottom: 1rem;
        color: rgba(255, 255, 255, 0.75);
        transition: all .2s ease;

        &:hover {
          color: var(--color-1);
        }
      }
    }

    @media (max-width: 768px) {
      .--content {
        flex-flow: column;
        padding: 24px 0;
      }

      .--left {
        width: 100%;
      }

      .--right {
        width: 100%;
        margin-top: 1rem;
        flex-wrap: wrap;
      }

      .footer_link {
        width: 50%;
        margin-bottom: 1rem;

        span {
          margin-bottom: 12px;
        }

        .--link {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer_coppy {
    padding: 8px 0;
    background: rgba(0, 0, 0, 0.25);

    .--link {
      margin-right: 2.5rem;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.75);
      transition: all .2s ease;

      &:hover {
        color: var(--color-1);
      }
    }
  }

  .segTitle {
    font-size: 36px;
    font-weight: 700;
    @media (max-width: 768px){
      font-size: 28px;
    }
  }

  .main {
    padding: 100px 0;

    @media (max-width: 768px) {
      padding: 3rem 0;
    }
  }

  .Banner {
    position: relative;

    .Title {
      margin-bottom: 2.5rem;
    }
    button{
      display: none !important;
    }
    .list_img {
      img {
        height: 430px;
        width: 100%;
        object-fit: cover;
      }
    }

    .arrow_Banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      i {
        position: absolute;
        pointer-events: auto;
        cursor: pointer;
        top: 50%;
        transform: translate(0, -50%);
        width: 44px;
        height: 44px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-5);
        background: #FFF;

        &.nextarrow {
          right: 6%;
        }

        &.prevarrow {
          left: 6%;
        }
      }
    }

    @media (max-width: 768px) {
      .list_img {
        img {
          height: 300px;
        }
      }
    }
  }

  .cards {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #EEEEEE;

    h5 {
      background: #003049;
      margin-bottom: 0;
      padding: 10px 20px;
      color: #FFF;
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;
      }
    }

    .filter {
      padding: 16px 20px;
      background: #FFF;
    }

    .search {
      position: relative;

      input {
        width: 100%;
        height: 44px;
        padding-left: 40px;
        border: 1px solid #EDEDED;
        border-radius: 6px;
        background: #F1F1F1;

        &::placeholder {
          color: var(--color-5);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 0.5px var(--color-1);
        }

        &:focus-visible {
          outline: none;
        }
      }

      i {
        position: absolute;
        top: 50%;
        color: var(--color-5);
        transform: translate(0, -50%);
        left: 15px;
      }
    }

    .list_cate {
      padding: 1rem 0;

      h6 {
        font-weight: 600;
      }

      .--item {
        padding-bottom: 20px;
        margin-bottom: 1rem;
        border-bottom: 1px solid #EDEDED;

        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
      }

      .list_check {
        margin-bottom: 0;

        li {
          margin-bottom: 0.75rem;
          label{
            display: flex;
            align-items: center;
            span{
              &:nth-child(1){
                top: 0;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

      }
    }

    @media (max-width: 768px) {
      margin-bottom: 0.75rem;
    }
  }

  .star {
    i{
      color:  #F6AF01;
    }
    svg{
      width: 16px;
    }
    ul{
      display: flex;
    }
    li:not(:last-child){
      margin-right: 4px;
    }
  }

  .list_product {
    .col-md-4,
    .col-md-3 {
      display:flex
    }

    .--item {
      position: relative;
      border: 1px solid #EEEEEE;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 20px;
      display: inherit;
      flex-direction: column;

      .--img {
        width: 100%;
        height: 200px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 20%;
          background: linear-gradient(180deg, #000000 0%, rgba(242, 242, 242, 0) 100%);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .--tag {
        font-size: 12px;
        text-transform: uppercase;
      }

      .--logo {
        position: absolute;
        left: 1rem;
        width: 60px;
        height: 60px;
        top: 150px;
        background: #FFF;
        border: 1px solid #DDDDDD;
        border-radius: 12px;

        img {
          max-width: 80%;
          max-height: 80%;
          height: auto;
        }
      }

      .--txt {
        padding: 24px 16px 12px;
        background: #FFF;
        flex:1;
        justify-content: space-between;
        display: inherit;
        flex-direction: column;
      }

      h5 {
        font-weight: 600;
        transition: all .15s ease;
        &:hover{
          color: var(--color-1);
        }
      }

      article {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;
        -webkit-line-clamp: 3;
        height: 72px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        margin-bottom: 2rem;
      }

      .--tag {
        gap: 4px;
        .--link {
          font-size: 12px;
          border-radius: 3px;
          padding: 2px 4px;
          margin-right: 4px;

          &:nth-child(n+1) {
            background: #EEEEEE;
            color: #6C6C6C;
          }

          &:nth-child(2n) {
            background: rgba(150, 89, 228, 0.1);
            color: #9659E4;
          }
        }
      }

      .stickers {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 8px;

        .hotnews {
          span {
            height: 30px;
            padding: 0 8px;
            display: flex;
            align-items: center;
            background: #FFF;
            border-radius: 6px;

            &:nth-child(1) {
              color: #FF5718;
              margin-right: 12px;

              img {
                margin-right: 4px;
              }
            }

            &:nth-child(2) {
              color: #24C768;
            }
          }
        }

        .heart {
          background: rgba(0, 0, 0, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.25);
          border-radius: 6px;
          width: 36px;
          height: 36px;
          color: #FFF;

          &.active {
            background: #FFC124;
            border: 1px solid rgba(255, 255, 255, 0.25);
          }
        }
      }
    }
    @media (max-width: 768px){
      .col-md-4{
        &:last-child{
          .--item{
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .cateProduct {
    .Title {
    }
  }

  @media (max-width: 768px){
    .cateProduct {
      .Title {
        margin-bottom: 2.5rem;
        flex-flow: column;
        align-items: flex-start !important;
      }
    }
  }

  .paying {

    .ant-select {
      margin: 0 6px;
      height: 36px;
      &-selector {
        border: 1px solid #D7D7D7;
        border-radius: 4px;
      }
    }

    .pagination {
      li {
        &:hover {}

        &:first-child {
          margin-right: 8px;

          .page-link {
            border: 1px solid #EDEDED;
            border-radius: 4px;
            background: #FFF;

          }
        }

        &:last-child {
          margin-left: 8px;

          .page-link {
            border: 1px solid #EDEDED;
            border-radius: 4px;
            background: #FFF;
          }
        }

        .page-link {
          color: #878787;
          border: none;
          background: none;
          width: 32px;
          height: 32px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active {
            background: var(--color-1);
            color: #FFF;
            border-radius: 4px;
          }
        }
      }
    }
    @media (max-width: 768px){
      flex-flow: column;
      margin-top: .5rem;
      align-items: flex-start !important;
      nav{
        width: 100%;
      }
      .perPage{
        margin-bottom: .5rem;
      }
    }
  }

  .form_pay {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 16px 24px;

    .--select {
      color: var(--color-1);
    }

    .--product {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #EEEEEE;

      .--item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .--logo {
        width: 44px;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 30px;
          max-height: 30px;
        }
      }

      h6 {
        margin-bottom: 0;
      }
    }

    .intomoney {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #EEEEEE;

      p {
        margin-bottom: 0;
      }

      span {
        color: var(--color-4);
        font-weight: 600;
      }
    }

    button {
      width: 100%;
    }

    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  }

  .policies {
    /* margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #EEEEEE; */
    .--link {
      color: var(--color-1) !important;
    }
  }

  button {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }

    &.add {
      color: var(--color-1);
    }

    &.erase {
      color: var(--color-4);
    }
  }

  table {
    width: 100%;
    margin-bottom: 8px;

    tbody {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
      border-radius: 0 0 6px 6px;
    }

    .check {
      width: 24px;
      height: 24px;
    }

    &.tab {
      border-radius: 6px 6px 0 0;
      background: #FFF;

      th {
        font-weight: 600;

        &:nth-child(n+2) {
          text-align: center;
        }

        span {
          color: var(--color-6);
          font-weight: 400;
        }
      }

      tr {
        height: 56px;
        padding: 0 24px;
        display: flex;
        align-items: center;
      }
    }

    &.product {
      padding: 20px 0 8px;
      display: flex;
      background: #FFF;
      flex-flow: column;

      h6 {
        font-weight: 600;
      }

      .logo {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        border: 1px solid #DDDDDD;
        border-radius: 6px;
        align-items: center;

        img {
          max-width: 30px;
          max-height: 30px;
        }
      }

      tr {
        display: flex;

        th {
          &:nth-child(1) {
            display: flex;
            align-items: center;
          }
        }

        td {
          display: flex;
          align-items: center;
        }

        &:nth-child(n+2) {
          height: 56px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            border-bottom: 1px solid #EEEEEE;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }

      }
    }
  }

  .modal_regis {
    .modal-content {
      padding: 24px;
    }
    .form_regis{
      #password{
        border: none !important;
      }
    }
    .modal-header {
      align-items: flex-start;
      position: relative;

      h3 {
        font-weight: bold;
        margin-bottom: 1rem;
        line-height: 1;
        text-transform: uppercase;
      }

      button {
        position: absolute;
        top: -24px;
        right: -24px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none !important;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .form-group {
      position: relative;

      i {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
      }

      input {
        &:focus {
          box-shadow: 0 0 0 0.5px var(--color-1);
        }
      }

      select {
        &:focus {
          box-shadow: 0 0 0 0.5px var(--color-1);
        }
      }
    }
    textarea.ant-input{
      padding-top: 10px;
    }
    .regis {
      button {
        width: 100%;
      }
    }
    .login {
      margin-top: 2.5rem;
      text-align: center;

      button {
        color: var(--color-1);
        text-transform: uppercase;
      }
    }
    .button {
      width: max-content;
      border-radius: 6px;

      button {
        height: 44px;
        border: 1px solid var(--color-1);
        width: 110px;
        padding: 0;
        &:nth-child(1) {
          border-radius: 6px 0 0 6px;
        }

        &:nth-child(2) {
          border-radius: 0 6px 6px 0;
        }

        &.active {
          background: var(--color-1);
          color: #FFF;
        }
      }

    }
  }

  // acount//
  #acount{
    padding-top: 118px;
    @media (max-width: 768px){
      padding-top: 110px;
    }
  }
  .acountForm {
    table {
      &.tab {
        tr {}

        th {
          &:nth-child(1) {
            width: 40%;
          }

          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(3) {
            width: 12%;
          }

          &:nth-child(4) {
            width: 18%;
          }

          &:nth-child(5) {
            width: 15%;
          }
        }
      }

      &.product {
        tr {
          padding: 0 24px;
          display: flex;

          &:nth-child(n+2) {
            &::after {
              width: calc(100% - 76px);
            }
          }
        }

        td {
          justify-content: center;

          &:nth-child(1) {
            width: 40%;
            justify-content: flex-start;
            padding-left: 52px;
          }

          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(3) {
            width: 12%;
          }

          &:nth-child(4) {
            width: 18%;
          }

          &:nth-child(5) {
            width: 15%;
            justify-content: end;
          }
        }

        span {
          &.sd {
            padding: 2px 12px;
            border-radius: 100px;
          }

          &.dsd {
            background: #F1F1F1;
          }

          &.ddsd {
            color: var(--color-4);
            background: rgba(243, 91, 4, 0.1);
          }

          &.csd {
            color: var(--color-1);
            background: rgba(0, 109, 183, 0.05);
          }
        }
      }
    }
  }

  .personalinfo {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 20px;

    .--avatar {
      width: 64px;
      height: 64px;
      margin-right: 12px;
      position: relative;
      flex: 0 0 auto;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      button {
        position: absolute;
        bottom: -4px;
        right: -4px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #FFF;

        i {
          color: #828282;
        }
      }
    }

    .--info {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid #F2F2F2;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        border-bottom: 2px solid var(--color-1);
        width: 50px;
      }

      h6 {
        font-weight: 600;
      }

      span {
        color: var(--color-6);
        word-break: break-all;
      }
    }

    .card-header {
      background: none;

      button {
        color: #000;
        font-weight: 600;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 0;
        box-shadow: inset 0px -1px 0px #F2F2F2;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        i {
          &.fa-angle-right {
            display: none;
          }
        }

        &:focus-visible {
          outline: none;
        }

      }
    }

    .card-body {
      display: flex;
      flex-flow: column;

      button {
        text-align: left;
        padding: 12px 0 12px 20px;
        box-shadow: inset 0px -1px 0px #F2F2F2;
        transition: all .15s ease;
        &:hover{
          color: var(--color-1);
        }
        &.active {
          color: var(--color-1);
        }
      }
    }
    @media (max-width: 768px){
      margin-bottom: 1.5rem;
    }
  }

  .acount_Supportticket {
    .filter {
      margin-bottom: 12px;

      select {
        height: 36px;
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        background: #FFF;
        padding: 0 12px;
        width: 180px;

        &:focus {
          outline: none;
          box-shadow: 0 0 0 .5px var(--color-1);
        }

        &:focus-visible {
          outline: none;
        }
      }

      .search {
        position: relative;

        input {
          height: 36px;
          padding-left: 36px;
          width: 200px;
          border: 1px solid #EEEEEE;
          border-radius: 6px;
          background: #FFF;

          &:focus {
            outline: none;
            box-shadow: 0 0 0 .5px var(--color-1);
          }

          &:focus-visible {
            outline: none;
          }
        }

        i {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          color: var(--color-5);
          font-size: 14px;
          left: 12px;
        }
      }

      button {
        height: 36px !important;
        padding: 0 12px !important;
      }
    }

    .list_Supportticket {
      .--item {
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px 20px 12px;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      .logo {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 6px;

        img {
          max-width: 30px;
          max-height: 30px;
        }
      }

      article {
        padding-bottom: 1rem;
        margin-bottom: 12px;
      }

      .--bot {
        padding-top: 1rem;
        border-top: 1px solid #EEEEEE;
        span {
          &:nth-child(1) {
            background: #F1F1F1;
            border: 1px solid rgba(167, 167, 167, 0.09);
            border-radius: 100px;
            padding: 3px 12px;
            font-weight: 500;
          }

          &:nth-child(2) {
            color: var(--color-6);
          }
        }
      }
    }
    @media (max-width: 768px){
      .filter{
        flex-flow: column;
        align-items: flex-start !important;
        h5{
          margin-bottom: 1rem !important;
        }
      }
    }
  }

  .acount_notification {
    .--top {
      margin-bottom: 12px;

      h5 {
        font-weight: 600;
      }

      button {
        color: var(--color-1);
      }
    }


    @media (max-width: 768px){
      .--top{
        flex-flow: column;
        align-items: flex-start;
        h5{
          margin-bottom: 1rem !important;
        }
      }
      .list_notification{
        .--item{
          flex-flow:column ;
          border-bottom: 1px solid var(--color-5);
          &:hover{
            border-radius:0 ;
            background: none;
          }
          .--name{
            margin-bottom: .5rem;
          }
        }
      }
    }
  }
  .list_notification {
    padding: 8px 16px;
    background: #FFFFFF;
    border-radius: 6px;
    .--item {
      padding: 12px 12px;
      transition: all .3s ease;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      h6 {
        font-weight: 600;
        margin-top: 4px;
      }

      .--des {
        span {
          color: var(--color-1);
        }
      }

      i {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F1F1F1;
        flex: 0 0 auto;
        color: var(--color-5);
        border-radius: 50%;
        transition: all .3s ease;
        margin-right: 12px;
      }

      &.--seen {
        background: linear-gradient(0deg,rgba(0,109,183,0.05),rgba(0,109,183,0.05)),#FFFFFF;
        border-radius: 6px;
      }

      &:hover {
        background: linear-gradient(0deg, rgba(0, 109, 183, 0.05), rgba(0, 109, 183, 0.05)), #FFFFFF;
        border-radius: 6px;

        i {
          background: var(--color-1);
          color: #FFF;
        }
      }
    }
  }
  .acount_profile {
    .--top {
      margin-bottom: 12px;

      h5 {
        font-weight: 600;
      }

      button {
        color: var(--color-1);

        i {
          margin-right: 12px;
        }
      }
    }

    .form_profile {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 16px 22px;
    }

    .form-group {
      margin-bottom: 12px;
      width: 100%;

      label {
        color: #BDBDBD;
        margin-bottom: 6px;
      }

      &.w-50 {
        width: calc(50% - 6px) !important;
      }

      input {
        height: 46px;
        width: 100%;
        background: #F6F6F6;
        border-radius: 4px;
        border: none;
        padding: 0 16px;

        &:focus {
          outline: none;
          box-shadow: 0 0 0 .5px var(--color-1);
        }

        &:focus-visible {
          outline: none;
        }
      }

      select {
        height: 46px;
        width: 100%;
        background: #F6F6F6;
        padding: 0 16px;
        border-radius: 4px;
        border: none;

        &:focus {
          outline: none;
          box-shadow: 0 0 0 .5px var(--color-1);
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    &.edit {
      input {
        border: 1px solid #EEEEEE;
        background: #FFF;
        border-radius: 4px;
      }
    }
  }

  //billing//
  #bill{
    padding-top:110px ;
  }
  .form {
    margin-bottom: 2rem;

    h5 {
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .radio {
      margin-bottom: 1rem;

      input {
        margin-right: 12px;
        width: 20px;
        height: 20px;
      }

      label {}

      .--item {
        margin-right: 2rem;
      }
    }

    .form-group {
      width: 100%;
      margin-bottom: 12px;

      &.w-50 {
        width: calc(50% - 6px) !important;
      }

      &.w-30 {
        width: calc(100%/3 - 8px);
      }

      label {
        margin-bottom: 6px;
      }

      input {
        border: 1px solid #EEEEEE;
        border-radius: 4px;
        height: 46px;
        padding: 0 16px;
        color: #000;
        width: 100%;

        &::placeholder {
          color: #BDBDBD;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 0.5px var(--color-1);
        }

        &:focus-visible {
          outline: none;
        }
      }

      select {
        border: 1px solid #EEEEEE;
        border-radius: 4px;
        height: 46px;
        padding: 0 16px;
        color: #BDBDBD;
        width: 100%;

        &:focus {
          outline: none;
          box-shadow: 0 0 0 0.5px var(--color-1);
        }

        &:focus-visible {
          outline: none;
        }
      }

    }

    button {
      padding: 16px 20px;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      border: 1px solid #E0E0E0;
      background: #FFFFFF;

      img {
        max-height: 42px;
      }
    }

    @media (max-width: 768px) {
      .form-group {
        &.w-30 {
          width: 100%;
        }

        &.w-50 {
          width: 100% !important;
        }
      }
    }
  }

  .billForm {
    .--left {
      padding: 24px;
      background: #FFFFFF;
      border-radius: 6px;
    }
  }

  //cart//
  #cart{
    @media (max-width: 768px) {
      padding-top: 110px;
    }
  }
  .cartForm {
    h3 {
      span {
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        padding: 2px 10px;
        font-size: 18px;
        color: var(--color-6);
        background: #FFF;
      }
    }

    table {
      &.tab {
        th {
          &:nth-child(1) {
            width: 24px;
          }

          &:nth-child(2) {
            text-align: left;
            padding-left: 24px;
            width: calc(40% - 24px);
          }

          &:nth-child(3) {
            width: 15%;
          }

          &:nth-child(4) {
            width: 13%;
          }

          &:nth-child(5) {
            width: 16%;
          }

          &:nth-child(6) {
            width: 18%;
          }

          &:nth-child(7) {
            width: 8%;
            text-align: right;
          }
        }
      }

      &.product {
        tr {
          padding: 0 24px;

          &:nth-child(n+2) {
            position: relative;

            &::after {
              width: calc(100% - 124px);
            }

            td {
              &:nth-child(n+2) {
                justify-content: center;
              }

              &:nth-child(1) {
                width: 40%;
                padding-left: 100px;
              }

              &:nth-child(2) {
                width: 13%;
              }

              &:nth-child(3) {
                width: 15%;

                select {
                  width: 62px;
                  height: 36px;
                  border: 1px solid #EEEEEE;
                  border-radius: 6px;
                  padding: 0 12px;

                  &:focus {
                    outline: none;
                  }

                  &:focus-visible {
                    outline: none;
                  }
                }
              }

              &:nth-child(4) {
                width: 16%;
              }

              &:nth-child(5) {
                width: 18%;
              }

              &:nth-child(6) {
                width: 8%;
                justify-content: end;
              }
            }
          }

          &:nth-child(1) {
            justify-content: space-between;
            align-items: center;
          }

          .logo {
            margin-left: 24px;
          }

        }
      }
    }
    @media (max-width: 768px) {
      .--left{
        overflow-x: scroll;
      }
      table{
        width: 800px;
      }
    }
  }

  // detailproduct//
  #detailpro {
    padding-top: 118px;
    @media (max-width: 768px) {
      padding-top: 110px;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 109, 183, 0.2);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .detailproInfo {
    background: #FFF;
    padding-bottom: 2rem;
    // position: fixed;
    // top: 118px;
    // width: 100%;
    // z-index: 99;
    .breadcrumb {
      margin-top: 0 !important;
    }
    @media (max-width: 768px) {
      padding-top: 12px;
      .breadcrumb {
        flex-wrap:wrap;
        width: 100%;
        .--item{
          margin-bottom: 4px;
        }
      }
    }
  }

  .introProduct {
    @media (max-width: 768px) {
      flex-flow: column;
    }
  }

  .infoProduct {
    .logo {
      width: 110px;
      height: 110px;
      margin-right: 2rem;
      border: 1px solid #DDDDDD;
      border-radius: 12px;

      img {
        max-width: 85px;
        max-height: 85px;
      }
    }

    .--info {
      h2 {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .--icon {
          margin-left: 12px;
          font-size: 18px;
          border: 1px solid #DDDDDD;
          color: #DDDDDD;
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          &.active {
            border: 1px solid #F72585;
            color: #F72585;
          }
        }
      }

      .--number {
        margin-left: 6px;
        color: var(--color-6);

        span {
          &:nth-child(1) {
            margin-right: 12px;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              border-right: 1px solid #C5C5C5;
              height: 10px;
              left: calc(100% + 6px);
            }
          }
        }
      }

      .--brand {
        span {
          color: var(--color-4);
          background: rgba(243, 91, 4, 0.1);
          padding: 4px 12px;
          border-radius: 4px;
        }
      }
    }

    @media (max-width: 768px) {
      flex-flow: column;

      .--info {
        margin-bottom: 1rem;

        .--brand {
          flex-wrap: wrap;
          display: flex;
          margin-top: 1rem;

          span {
            margin-bottom: .5rem;
            display: flex;
            width: max-content;
          }
        }
      }
    }
  }

  .nav {
    background: #fff;
    position: sticky;
    top: 118px;
    z-index: 50;
    width: 100%;
    padding: 12px 0;
    @media (max-width: 768px) {
      top: 110px;
    }
    .tabproInfo {
      .--link {
        padding: 12px 24px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);

        &.active {
          color: var(--color-1);
          border-bottom: 1px solid var(--color-1);
        }
      }

      @media (max-width: 768px) {
        display: flex;
        margin-top: 1rem;
        overflow-x: scroll;

        span {
          flex: 0 0 auto;
        }
      }
    }
  }

  .detailproOverview {
    h1 {
      margin-bottom: 1.5rem;
    }

    .--left {
      width: calc(50% - 50px);
    }

    .--right {
      width: calc(50% - 50px);
    }

    .linkoverview {
      h5 {
        margin-bottom: 12px;
        font-weight: 600;
      }

      ul {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        flex-flow: column;
        li{
          margin-bottom: 1rem;
          &:last-child{
            margin-bottom: 0;
          }
        }
        i {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          width: 40px;
          height: 40px;
          background: #F1F1F1;
          border-radius: 50%;
          color: #969696;
        }

        .--txt {
          span {
            color: rgb(44 44 44 / 50%);

            &:nth-child(2) {
              color: var(--color-1);
              font-weight: 600;
            }
          }
        }
      }
    }

    .product_library {
      button {
        display: none !important;
      }

      .--img {
        width: 100%;
        height: 380px;
        border-radius: 6px;
        overflow: hidden;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .--link {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 90px;
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background: #FFF;

          i {
            font-size: 16px;
          }
        }
      }
    }

    .tabproduct_library {
      span {
        width: 110px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        height: 84px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: #2C2C2C;
        font-size: 12px;
        opacity: 0.5;

        &.active {
          border: 1px solid var(--color-1);

          i {
            color: var(--color-1);
          }
        }

        i {
          height: 24px;
          font-size: 14px;
          margin-bottom: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    @media (max-width: 768px) {
      .--content {
        flex-flow: column;
      }

      .--left {
        width: 100%;
        margin-bottom: 1.5rem;
      }

      .--right {
        width: 100%;
      }

      .linkoverview {
        margin-top: 1rem;

        ul {
          flex-flow: column;

          li {
            width: 100%;
            margin-bottom: .5rem;
          }
        }
      }

      .product_library {
        .--img {
          height: 250px;
        }
      }

      .tabproduct_library {
        justify-content: space-between;

        span {
          margin-right: 0 !important;
          width: calc(100%/3 - 6px);
        }
      }
    }
  }

  .detailproAdvan {
    .--content {
      padding: 84px 60px;
      background: #003049;
      position: relative;
      overflow: hidden;
      border-radius: 16px;

      .partent {
        position: absolute;
        top: 0;
        pointer-events: none !important;
        left: 50%;
        width: calc(100% - 120px);
        transform: translate(-50%, 0);

        img {
          width: 100%;
        }
      }

      h1 {
        text-align: center;
        color: #FFF;
        margin-bottom: 2rem;
      }
    }

    .list_detailproAdvan {
      position: relative;
      z-index: 1;
      display: grid !important;
      grid-template-columns: repeat(4,1fr);
      gap: 16px;

      .--item {
        /* width: calc(25% - 12px); */
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 8px;
        position: relative;
        padding: 16px;
        /* height: 210px; */
        /* margin-right: 16px; */
        &:last-child{
          margin-right: 0;
        }
        .--number {
          font-size: 38px;
          color: #EEEEEE;
          line-height: 1;
        }

        h6 {
          letter-spacing: 0.25em;
          text-transform: uppercase;
          font-weight: 700;
          margin-bottom: 12px;
        }

        article {
          position: relative;
          z-index: 2;
        }


        .--icon {
          position: absolute;
          bottom: 16px;
          right: 16px;
        }
      }
    }

    @media (max-width: 768px) {
      .--content {
        padding: 32px 16px 16px;
      }

      .list_detailproAdvan {
        flex-flow: column;

        .--item {
          margin-bottom: 1rem;
          width: 100%;
        }
      }
    }
  }

  .detailproFeature {
    h1 {
      margin-bottom: 2.5rem;
    }

    .list_proFeature {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -10px;
        width: calc(32% + 10px);
        border-radius: 8px;
        height: 100%;
        background: rgba(0, 109, 183, 0.05);
        z-index: -1;
      }
    }

    .list_img {
      width: calc(68% - 50px);

      .--img {
        width: 100%;
        height: 460px;
        display: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.active {
          display: block;
        }
      }
    }

    .list_Feature {
      overflow-y: auto;
      width: calc(32% + 50px);
      height: 460px;
      padding: 16px 18px 16px 74px;
      position: relative;
      .card-header {
        background: none;
      }

      .card-body {
        padding: 0;
        color: var(--color-6);
        font-size: 14px;
      }

      .card {
        background: none;
        transition: all .3s ease;
        width: 90%;
        &:last-child{
          margin-bottom: 0 !important;
        }
        &.active {
          width: calc(100% + 3rem);
          background: #FFF;
          box-shadow: 0px 4px 20px rgb(0 79 133 / 8%);
          border-radius: 8px;
          padding: 16px 12px;
          margin-left: -3rem;

          .--txt {
            align-items: flex-start;

            i {
              background: var(--color-1);
              color: #FFF;
            }
          }
          button {
            .--arrow {
              i {
                &.fa-chevron-down {
                  display: none;
                }

                &.fa-chevron-up {
                  margin-top: 4px;
                  display: block;
                }
              }
            }
          }

          button {
            align-items: flex-start !important;
          }
        }
      }

      button {
        width: 100%;
        text-decoration: none;
        border: none;
        background: none;
        color: #000000;

        &:focus {
          box-shadow: none;
        }

        &:focus-visible {
          outline: none;
        }

        .--arrow {
          i {
            font-size: 12px;

            &.fa-chevron-down {
              display: block;
            }

            &.fa-chevron-up {
              display: none;
            }
          }
        }

        &.collapsed {
          i {
            &.fa-chevron-up {
              display: none !important;
            }

            &.fa-chevron-down {
              display: block;
            }
          }
        }

        .card-header {
          border: none;
        }
      }

      .--des {
        text-align: left;
        width: 75%;
      }

      .--txt {
        font-size: 16px;
        transition: all .5s ease;
        align-items: center;

        h6 {
          font-weight: 600;
          margin-bottom: 0;
          line-height: 28px;
        }

        i {
          background: rgba(0, 109, 183, 0.1);
          border-radius: 8px;
          width: 48px;
          height: 48px;
          flex: 0 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;
          color: var(--color-1);
        }
      }
    }
    @media (max-width: 768px){
      .list_proFeature{
        flex-flow: column;
        background: rgba(0,109,183,0.05);
        &::after{
          display: none;
        }
        .list_img{
          width: 100%;
          .--img{
            height: 220px;
          }
        }
        .list_Feature{
          width: 100%;
          height: auto;
          padding:16px;
          .card{
            width: 100%;
            padding:0 12px;
            &.active{
              padding: 16px 12px;
              width: 100%;
              margin-left: 0;
            }
          }
        }
      }
    }
  }


  .detailproRating {

    .--content {
      h1 {
        margin-bottom: 36px;
      }

      .ant-rate {
        color: #F6AF01;

      }

      .list-proRating {
        padding: 36px 0;
        border-radius: 8px;
        background-color: white;
        display: grid;
        grid-template-columns: 24% 74%;
        column-gap: 20px;
        width: 100%;

        @media (max-width: 768px) {
          grid-template-columns: 100%;

          .--right {
            .--header {
              flex-direction: column;
            }
          }
        }

        .--left {
          padding: 24px 44px;
          align-items: center;
          border-right: 1px solid #EEEEEE;

          .--average {
            align-items: center;

            h3 {
              font-weight: 600;
              font-size: 80px;
              line-height: 80px;
              margin-bottom: 19.5px;
            }

            span {
              font-size: 25px;
            }

            .--total {

              color: #727272;
            }

          }

          ul.--item {
            padding: 0;
            margin: 0;
            width: 100%;

            &>li {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #EEEEEE;
              border-radius: 4px;
              padding: 2px 10px;
              margin-bottom: 4px;
              cursor: pointer;
              &.active {
                border: 1px solid #f8b940;
              }

              .ant-rate-star {
                font-size: 16px;
                margin-right: 1px;
              }

              &>span:last-child {
                color: #898989;
              }
            }
          }
        }




        .--right {
          padding: 0px 24px;

          .--header {
            justify-content: space-between;

            h4 {
              font-weight: 700;
              font-size: 24px;
              line-height: 36px;
              color: #111111;
              margin: 0;
            }

            button {
              padding: 10px 20px;
              color: white;
              font-size: 16px;
              background: #006DB7;
              border-radius: 8px;
              cursor: pointer;

              i {
                margin-right: 10px;
              }
            }

            button:hover {
              opacity: 90%;
            }

            padding-bottom: 10px;
            margin-bottom: 28px;
            border-bottom: 1px solid #EEEEEE;
          }

          .--list {

            margin: 0;
            padding: 0
          }

          .--more {
            padding: 10px 0;
            width: 100%;
            background: #F5F5F5;
            border-radius: 8px;

            div {
              margin: auto;
              font-size: 16px;
              line-height: 24px;

              text-transform: uppercase;

              color: #006DB7;

              span {
                margin-right: 14px;
              }
            }

          }

          .--item {
            margin-bottom: 20px;

            img {
              width: 48px;
              height: 48px;
              margin-right: 14px;
              border-radius: 50%;
              object-fit: cover;

            }


            .--info {
              flex-direction: column;

              .ant-rate-star {
                font-size: 16px;
                margin-right: 1px;
              }

              h5 {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
              }

              .divider {
                margin: 0 16px;
                height: 16px;
              }

              &>div {
                i {
                  margin-right: 8px;
                }

                color: #898989;
              }

              &>span {
                margin-top: 8px;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }


        }
      }
    }

  }


  .detailproPrice {
    h1 {
      margin-bottom: 2rem;
    }

    .list_detailproPrice {
      .--item {
        width: calc(50% - 10px);
        background: #FFF;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        position: relative;
        margin-bottom: 20px;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          border-left: 1px dashed #DADADA;
          height: 100%;
        }
      }

      .--tag {
        background: #F1F1F1;
        border-radius: 4px;
        padding: 2px 10px;
        width: max-content;
        margin-bottom: 12px;
      }

      .--price {
        margin-bottom: 8px;

        span {
          &:nth-child(1) {
            font-size: 24px;
            font-weight: 700;
          }

          &:nth-child(2) {
            font-size: 16px;
            color: var(--color-6);
          }
        }
      }

      .--time {
        color: var(--color-6);
        margin-bottom: 20px;

        select {
          height: 30px;
          color: #000;
          padding: 0 10px;
          border: 1px solid #EEEEEE;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
          border-radius: 6px;
          background: #FFF;
          margin-left: 8px;
        }
      }

      button {
        padding: 0px 12px;
        height: 32px;
        border: none;
        + button {
          margin-left: 6px;
        }
      }

      ul {
        i {
          width: 12px;
          height: 12px;
          background: var(--color-1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          margin-right: 8px;
          color: #FFF;
        }

        li {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .--hidden {
        margin-top: 10px;
        margin-left: 35px;
        color: var(--color-1);
        font-size: 13px;
        display: flex;
      }
    }
    @media (max-width: 768px){
      .list_detailproPrice{
        flex-flow: column;
        .--item{
          width: 100%;
          flex-flow: column;
          .--left{
            width: 100% !important;
            margin-bottom: 1rem;
          }
          .--right{
            width: 100% !important;
            padding: 0;
          }
          &::after{
            display: none;
          }
          &:last-child{
            margin-bottom: 0;
          }
          &:nth-last-child(2){
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .detailproQuestions {
    h1 {
      margin-bottom: 32px;
    }

    .list_question {
      .card-header {
        background: none;
        border: none;
      }

      .card {
        border: 1px solid #EDEDED;
        border-radius: 8px;
        margin-bottom: 8px;
        width: calc(50% - 10px);
        padding: 1rem;
        &.active {
          i {
            &.fa-plus {
              display: none;
            }

            &.fa-minus {
              display: block;
            }
          }
        }
      }

      button {
        width: 100%;
        display: flex;
        text-align: left;
        text-decoration: none;
        color: #000;

        &:focus {
          box-shadow: none !important;
        }

        i {
          margin-top: 3px;

          &.fa-plus {
            display: block;
          }

          &.fa-minus {
            display: none;
            color: var(--color-1);
          }
        }

        .--txt {
          margin-left: 1rem;
        }

        h6 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0;
        }
      }

      .card-body {
        margin-top: 20px;
      }
    }
    @media (max-width: 768px){
      .list_question{
        flex-flow: column;
        .card{
          width: 100%;
        }
      }
    }
  }

  .detailproProduct {
    .--title {
      margin-bottom: 2rem;
    }

    .col-md-3 {
      padding: 0 12px;
    }

    .arrow_detailproProduct {
      i {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.nextarrow {
          background: #FFFFFF;
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        }

        &.prevarrow {
          background: #E2E2E2;
          margin-right: 8px;
        }
      }
    }
  }

  //home//
  #home {
    padding-top: 118px;
    @media (max-width: 768px) {
      padding-top: 110px;
    }
  }

  .homeProduct {
    .Title {
      margin-bottom: 2.5rem;
    }

    @media (max-width: 768px) {
      .Title {
        flex-flow: column;
        align-items: flex-start !important;

        h1 {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .homePost {
    .--title {
      border-bottom: 3px solid #F1F1F1;
      margin-bottom: 2rem;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        width: 90px;
        border-bottom: 3px solid var(--color-1);
        left: 0;
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
      }

      .--link {
        color: var(--color-6);
        font-size: 16px;

        i {
          width: 30px;
          height: 30px;
          background: #FFF;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          color: #2C2C2C;
          align-items: center;
          font-size: 12px;
        }
      }
    }

    .itemPost {
      width: calc(100%/3 - 30px);
    }

    .videoPost {
      .--img {
        width: 100%;
        height: 220px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .--link {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFF;
          border-radius: 50%;
          border: 1px solid #FFF;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        }
      }

      .--tag {
        span {
          color: #FFF;
          border-radius: 3px;
          line-height: 20px;
          padding: 0 8px;
          font-size: 12px;

          &:nth-child(1) {
            background: #003049;
            margin-right: 8px;
          }

          &:nth-child(2) {
            background: #F35B04;
          }
        }
      }

      .--time {
        color: var(--color-6);
      }

      h6 {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      article {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        -webkit-line-clamp: 2;
        height: 40px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .Interactive {
        span {
          color: var(--color-6);

          &:nth-child(1) {
            margin-right: 20px;
          }

          i {
            margin-right: 8px;
          }
        }
      }
    }

    .blogPost {
      .list_blog {
        .--item {
          margin-bottom: 1.5rem;
        }

        .--img {
          width: 150px;
          height: 110px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .--txt {
          width: calc(100% - 174px);
        }

        .--tagtime {
          span {
            &:nth-child(1) {
              margin-right: 20px;
              color: var(--color-1);
              text-transform: uppercase;
              font-weight: 700;
              font-size: 12px;
              letter-spacing: 0.15em;
              position: relative;

              &::after {
                content: '';
                position: absolute;
                left: calc(100% + 10px);
                width: 4px;
                background: #D9D9D9;
                height: 4px;
                border-radius: 50%;
                transform: translate(-50%, 0);
                bottom: 2px;
              }

            }
          }
        }

        h6 {
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 24px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          transition: all .15s ease;
          &:hover{
            color: var(--color-1);
          }
        }
      }
    }

    @media (max-width: 768px) {
      .--content {
        flex-flow: column;
      }

      .itemPost {
        width: 100%;
        margin-bottom: 2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .homeSupport {
    .--left {
      width: 45%;

      img {
        width: 100%;
        height: 470px;
        object-fit: cover;
      }
    }

    .--right {
      width: calc(55% - 80px);
    }

    h1 {
      font-weight: 400;
      span{
        font-weight: 700;
      }
    }

    ul {
      display: flex;
      padding-left: 0;
      justify-content: space-between;
      margin: 2.5rem 0;

      li {
        display: flex;
        width: calc(100%/3 - 24px);

      }

      .--link {
        display: flex;

        i {
          width: 40px;
          height: 40px;
          display: flex;
          background: var(--color-1);
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 1rem;
          flex: 0 0 auto;
          color: #FFF;
        }

        .--txt {
          display: flex;
          flex-flow: column;

          span {
            color: #000;

            &:nth-child(1) {
              font-size: 24px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .button {
      span {
        display: flex;
        align-items: center;

        i {
          font-size: 12px;
        }

        &:nth-child(1) {
          margin-right: 2.5rem;
        }

        &:nth-child(2) {
          color: var(--color-1);
          transition: all .15s ease;
          height: 40px;
          &:hover{
            font-weight: 600;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .--content {
        flex-flow: column;

        .--left {
          width: 100%;

          img {
            height: 200px;
          }
        }

        .--right {
          width: 100%;
        }
      }

      ul {
        flex-flow: column;
        margin: 1rem 0 1.5rem;

        li {
          width: 100%;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .--link {
          align-items: center;
        }
      }

      .button {
        flex-flow: column;
        align-items: flex-start !important;

        span {
          &:nth-child(1) {
            margin-bottom: 1rem;
          }
        }
      }

      .--right {
        margin-top: 1.5rem;
      }
    }
  }

  .homeCustom {
    position: relative;
    background: #FFF;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      pointer-events: none;
      background: rgba(0, 109, 183, 0.05);
    }

    .list_homeCustom {
      width: 60%;
      .slick-slide {
        > div {
          width: 100%;
        }
      }
      .slick-slide {
        padding: 12px !important;
      }
      .slick-current {
        .--item {
          background: var(--color-1);
          color: #FFF;
          .--icon {
            svg {
              &:nth-child(1) {
                display: none;
              }

              &:nth-child(2) {
                display: block;
              }
            }
          }
        }
      }

      .--item {
        padding: 20px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 8px;

        .--icon {
          margin-bottom: 40px;

          img {
            &:nth-child(2) {
              display: none;
            }
          }
        }

        article {
          margin-bottom: 4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 22px;
          -webkit-line-clamp: 6;
          height: 132px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

        .--avatar {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid rgba(255, 255, 255, 0.5);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .--txt {
          span {
            &:nth-child(1) {
              font-weight: 600;
            }

            &:nth-child(2) {
              font-size: 12px;
            }
          }
        }
      }

      .slick-list {
        /* padding: 0 0 0 12% !important; */
        padding-left: 100px !important;
      }
    }

    .--content {
      position: absolute;
      top: 0;
      padding: 100px 0;
      width: 100%;
      left: 0;
      height: 100%;
      pointer-events: none;
      z-index: 1;

      .--txt {
        width: 30%;
        /* margin-left: 64%; */
      }

      h1 {
        font-size: 36px;

        span {
          color: var(--color-1);
        }
      }

      .--des {
        margin: 24px 0 20px;
      }

      article {
        padding-left: 24px;
        position: relative;
        line-height: 24px;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          height: 100%;
          left: 10px;
          border-left: 2px solid var(--color-1);
        }
      }
    }

    .arrow_homeCustom {
      margin-left: calc(59.5% - 96px);
      margin-top: 8px;

      i {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background: #FFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

        &:nth-child(1) {
          margin-right: 8px;
        }
      }
    }

    @media (max-width: 768px) {
      .--content {
        position: relative;
        top: unset;
        left: unset;
        pointer-events: auto;
        padding: 0;

        .--txt {
          width: 100%;
          margin-left: 0;
        }
      }

      .list_homeCustom {
        width: 100%;

        .slick-list {
          padding-left: 0 !important;
        }
      }

      .arrow_homeCustom {
        margin: auto;
        justify-content: center;
      }
    }
  }

  //payment//
  #pay {
    padding-top: 118px;
    @media (max-width: 768px) {
      padding-top: 110px;
    }
  }

  .payConfirmation {
    padding: 3.5rem 0;
    background: rgba(0, 109, 183, 0.05);

    .--content {
      text-align: center;
      width: 560px;
      margin: auto;

      img {
        max-height: 52px;
      }

      svg {
        color: #219653;
      }
      h3 {
        color: #219653;
        font-size: 24px;
        font-weight: 600;
      }

      &.--fail {
        svg {
          color: rgba(224, 28, 28, 1);
        }
        h3 {
          color: rgba(224, 28, 28, 1);
        }
      }
    }

    @media (max-width: 768px) {
      .--content {
        width: 100%;
      }
    }
  }

  .payForm {
    h3 {
      font-size: 24px;

      span {
        color: var(--color-1);
        margin-left: 8px;
      }
    }

    table {
      &.tab {
        th {
          &:last-child {
            text-align: right;
          }

          &:nth-child(1) {
            width: 57%;
          }


          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(3) {
            width: 18%;
            text-align: right;
          }

          &:nth-child(4) {
            width: 20%;
          }
        }
      }

      &.product {
        tr {
          width: 100%;
          padding: 0 24px;
          display: flex;

          &:nth-child(n+2) {
            &::after {
              width: calc(100% - 76px);
            }
          }
        }

        td {
          &:nth-child(n+2) {
            justify-content: center;
          }

          &:nth-child(1) {
            width: 57%;
            padding-left: 52px;
          }
          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(3) {
            width: 18%;
            justify-content: end;
          }

          &:nth-child(4) {
            width: 20%;
            justify-content: end;
            font-weight: 600;
          }
        }
      }

      &.pay {
        background: #FFF;
        padding: 16px 24px;
        display: flex;

        tr {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        td {
          &:nth-child(1) {
            h6 {
              font-weight: 600;
              margin-bottom: 0;
            }

            span {
              color: var(--color-6);
              font-size: 12px;
            }
          }

          &:nth-child(2) {
            font-size: 20px;

            span {
              color: var(--color-4);
            }
          }
        }
      }
    }

    .--infomation {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF;
      border: 1px dashed #B4B4B4;
      border-radius: 6px;
      padding: 16px 20px;

      .--item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        h6 {
          margin-bottom: 10px;
          font-weight: 700;
        }

        ul {
          padding-left: 0;
          margin-bottom: 0;
        }

        li {
          display: flex;
          margin-bottom: .5rem;
          word-break: break-word;

          p {
            margin-bottom: 0;

            &:nth-child(1) {
              width: 105px;
              font-weight: 600;
              flex: 0 0 auto;
            }

            span {
              color: var(--color-4);
              font-weight: 600;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      h3{
        flex-flow: column;
        display: flex;
        span{
          margin-left: 0;
          margin-top: 4px;
        }
      }
      .--left{
        overflow-x:scroll;
        margin-bottom: 1.5rem;
         table{
          width: 700px;
         }
      }
    }
  }

  .ant-modal {
    &-body {
      padding: 0;
    }
    &-close {
      display: none;
    }
  }
`;
