import { appActions } from 'app/pages/App/slice';
import {
  selectIsShowDialogLogin,
  selectIsShowDialogRegister,
  selectIsShowDialogForgetPassword,
} from 'app/pages/App/slice/selectors';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE_COOKIE } from 'utils/constants';
import { setCookie } from 'utils/cookies';

export const useGlobalFunction = () => {
  const dispatch = useDispatch();
  const isShowDialogLogin = useSelector(selectIsShowDialogLogin);
  const isShowDialogRegister = useSelector(selectIsShowDialogRegister);
  const isShowDialogForgetPassword = useSelector(
    selectIsShowDialogForgetPassword,
  );

  const onLogout = useCallback(() => dispatch(appActions.logout()), [dispatch]);

  const onOpenDialogLogin = useCallback(() => {
    dispatch(appActions.openDialogLogin());
  }, [dispatch]);

  const onOpenDialogRegister = useCallback(() => {
    dispatch(appActions.openDialogRegister());
  }, [dispatch]);

  const onOpenDialogForgetPassword = useCallback(() => {
    dispatch(appActions.openDialogForgetPassword());
  }, [dispatch]);

  const onCloseDialogAuth = useCallback(() => {
    dispatch(appActions.closeDialogAuth());
  }, [dispatch]);

  const onLoginSuccess = useCallback(
    token => {
      setCookie(TYPE_COOKIE.TOKEN, token);
      dispatch(appActions.changeIsLogged({ isLogged: true }));
      onCloseDialogAuth();
    },
    [dispatch, onCloseDialogAuth],
  );

  return useMemo(
    () => ({
      onLogout,
      onLoginSuccess,
      isShowDialogLogin,
      isShowDialogRegister,
      isShowDialogForgetPassword,
      onOpenDialogLogin,
      onOpenDialogRegister,
      onOpenDialogForgetPassword,
      onCloseDialogAuth,
    }),
    [
      onLogout,
      onLoginSuccess,
      isShowDialogLogin,
      isShowDialogRegister,
      isShowDialogForgetPassword,
      onOpenDialogLogin,
      onOpenDialogRegister,
      onOpenDialogForgetPassword,
      onCloseDialogAuth,
    ],
  );
};
