/**
 *
 * Asynchronously loads the component for MyAccount
 *
 */

import { lazyLoad } from 'utils/loadable';

export const MyAccount = lazyLoad(
  () => import('./index'),
  module => module.MyAccount,
);
