import React from 'react';
import { Route } from 'react-router-dom';

export const NormalRoute = ({
  component: Component,
  componentProps = {},
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => <Component {...props} {...componentProps} />}
    />
  );
};
