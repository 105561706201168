import { I_OptionsGetList } from 'types';

export enum TYPE_LOCAL_STORAGE {
  URL_REDIRECT = 'mobifone-studio-url-redirect',
}

export enum TYPE_COOKIE {
  TOKEN = 'mobifone-studio-token',
}

export const LIMIT_PAGE_SIZE = 6;

export const default_options_get_list: I_OptionsGetList = {
  limit: LIMIT_PAGE_SIZE,
  page: 1,
  search: '',
  order_by: '',
  order: 'desc',
};

export const STYLE_DARK_THEME_CONTENT_DIALOG = {
  color: '#fff',
  // border: '1px solid rgb(255 255 255 / .15)',
  backgroundColor: '#212121',
  // boxShadow: '0 12px 24px rgb(0 0 0  / .15)',
};

export const STYLE_DARK_THEME_CONTAINER_DIALOG = {
  boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.1)',
  border: '1px solid red',
};
