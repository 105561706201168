import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { cartSaga } from './saga';
import { CartState, I_CartItem } from './types';

export const GET_MY_CART = createRoutine('cart/getMyCart');
export const ADD_MY_CART = createRoutine('cart/addMyCart');
export const REMOVE_MY_CART = createRoutine('cart/removeMyCart');
export const UPDATE_MY_CART = createRoutine('cart/updateMyCart');

export const initialState: CartState = {
  loading: false,
  success: false,
  error: false,
  cart: [],
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetState(state) {
      Object.keys(state).forEach(item => {
        state[item] = initialState[item];
      });
    },
  },
  extraReducers: {
    /**
     * !GET_MY_CART
     */
    [GET_MY_CART.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_MY_CART.SUCCESS]: (state, action: PayloadAction<I_CartItem[]>) => {
      state.cart = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_MY_CART.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * !ADD_MY_CART
     */
    [ADD_MY_CART.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ADD_MY_CART.SUCCESS]: (state, action: PayloadAction<I_CartItem[]>) => {
      state.cart = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [ADD_MY_CART.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * !REMOVE_MY_CART
     */
    [REMOVE_MY_CART.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [REMOVE_MY_CART.SUCCESS]: (state, action: PayloadAction<I_CartItem[]>) => {
      state.cart = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [REMOVE_MY_CART.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions: cartActions } = slice;

export const useCartSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: cartSaga });
  return { actions: slice.actions };
};
