/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Redirect, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { useTranslation } from 'react-i18next';
import { OverlayThumb } from 'app/components/OverlayThumb/Loadable';
import CheckNewVersion from './components/CheckNewVersion';
import { LoadingOutlined } from '@ant-design/icons';
import { AppWrapper } from './styled';
import { Home } from '../Home/Loadable';
import { NormalRoute, ProtectedRoute } from 'app/components/RouteGuard';
import { Layout } from 'app/components/Layout/Loadable';
import { GET_LAYOUT, GET_ME, GET_NOTIFICATIONS, useAppSlice } from './slice';
import NotificationsSystem, {
  atalhoTheme,
  setUpNotifications,
  dismissNotification,
} from 'reapop';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCountListNotificationUnread,
  selectInfoUser,
  selectIsLogged,
  selectLayoutFooter,
  selectLayoutHeader,
  selectNotifications,
} from './slice/selectors';
import {
  DialogForgetPassword,
  DialogLogin,
  DialogRegister,
} from './components/Loadable';
import { useGlobalFunction } from 'utils/hooks/useGlobalFunction';
import { auth } from 'utils/helper';
import { Product } from '../Product/Loadable';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ProductDetail } from '../Product/Detail/Loadable';
import { MyAccount } from '../MyAccount/Loadable';
import { Cart } from '../Cart/Loadable';
import { GET_MY_CART, useCartSlice } from '../Cart/slice';
import { selectMyCart } from '../Cart/slice/selectors';
import { Checkout } from '../Checkout/Loadable';
import { PaymentCallback } from '../PaymentCallback/Loadable';

// run this function when your application starts before creating any notifications
setUpNotifications({
  defaultProps: {
    allowHTML: true,
    dismissible: true,
    dismissAfter: 2000,
    transition: 'slide',
    position: 'bottom-right',
    showDismissButton: true,
  },
});

export function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { actions } = useAppSlice();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actions: actionsCart } = useCartSlice();

  const myCart = useSelector(selectMyCart);
  const isLogged = useSelector(selectIsLogged);
  const infoUser = useSelector(selectInfoUser);
  const header = useSelector(selectLayoutHeader);
  const footer = useSelector(selectLayoutFooter);
  const notifications = useSelector(selectNotifications);
  const countNotificationUnread = useSelector(
    selectCountListNotificationUnread,
  );

  const {
    onLoginSuccess,
    onCloseDialogAuth,

    isShowDialogLogin,
    onOpenDialogLogin,

    isShowDialogRegister,
    onOpenDialogRegister,

    isShowDialogForgetPassword,
    onOpenDialogForgetPassword,
  } = useGlobalFunction();

  useEffect(() => {
    if (actions) {
      dispatch(GET_LAYOUT());
      dispatch(actions.changeIsLogged({ isLogged: auth() }));
    }
    return () => {
      dispatch(actions.resetState());
    };
  }, [actions, dispatch]);

  useEffect(() => {
    isLogged && dispatch(GET_ME());
    isLogged && dispatch(GET_MY_CART());
    isLogged && dispatch(GET_NOTIFICATIONS());
  }, [isLogged, dispatch]);

  return (
    <>
      <Helmet
        titleTemplate="%s - MobiFone KD Online"
        defaultTitle="Mobifone KD Online"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="MobiFone Kinh Doanh Online" />
      </Helmet>
      <CheckNewVersion>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading)
            return (
              <OverlayThumb icon={<LoadingOutlined />} label="Loading..." />
            );
          if (!loading && !isLatestVersion) refreshCacheAndReload();
          return (
            <BrowserRouter>
              <AppWrapper>
                <Layout
                  header={header}
                  footer={footer}
                  isLogged={isLogged}
                  infoUser={infoUser}
                  myCartCount={myCart.length || 0}
                  myNotificationsUnreadCount={countNotificationUnread}
                >
                  <Switch>
                    <NormalRoute path="/" exact component={Home} />
                    <NormalRoute exact path="/products" component={Product} />
                    <NormalRoute
                      path="/products/:id"
                      component={ProductDetail}
                    />
                    <ProtectedRoute path="/cart" component={Cart} />
                    <ProtectedRoute path="/checkout" component={Checkout} />
                    <ProtectedRoute
                      path="/payment-callback"
                      component={PaymentCallback}
                    />
                    <ProtectedRoute path="/my-account" component={MyAccount} />
                    <Redirect to="/" />
                  </Switch>
                </Layout>

                <NotificationsSystem
                  theme={atalhoTheme}
                  notifications={notifications}
                  dismissNotification={id => dispatch(dismissNotification(id))}
                />

                {isShowDialogLogin && (
                  <DialogLogin
                    isShow={isShowDialogLogin}
                    onLoginSuccess={onLoginSuccess}
                    onCloseDialog={onCloseDialogAuth}
                    onOpenDialogRegister={onOpenDialogRegister}
                    onOpenDialogForgetPassword={onOpenDialogForgetPassword}
                  />
                )}

                {isShowDialogRegister && (
                  <DialogRegister
                    isShow={isShowDialogRegister}
                    onCloseDialog={onCloseDialogAuth}
                    onOpenDialogLogin={onOpenDialogLogin}
                  />
                )}

                {isShowDialogForgetPassword && (
                  <DialogForgetPassword
                    isShow={isShowDialogForgetPassword}
                    onCloseDialog={onCloseDialogAuth}
                    onSuccess={onOpenDialogLogin}
                  />
                )}
              </AppWrapper>
            </BrowserRouter>
          );
        }}
      </CheckNewVersion>
      <GlobalStyle />
    </>
  );
}
