import React from 'react';
import { Modal } from 'antd';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { TYPE_COOKIE } from './constants';
import { getCookie } from './cookies';
import { nanoid } from '@reduxjs/toolkit';
import { notify, Status } from 'reapop';
import { store } from '../';
import { appActions } from 'app/pages/App/slice';
import { AppState } from 'app/pages/App/slice/types';

const { confirm } = Modal;

export const openNotificationWithIcon = (
  type: Status,
  message: string,
  description?: string,
  duration?: number,
) => {
  store.dispatch(
    notify(description || '', type, {
      title: message || 'Notification',
      dismissAfter: (duration || 3) * 1000,
    }),
  );
};

export const openMessageWithIcon = (
  type: Status,
  content: any,
  duration?: number,
) => {
  store.dispatch(
    notify(content || '', type, {
      dismissAfter: (duration || 3) * 1000,
    }),
  );
  // key && message.config({ key });
};

export const openConfirmModal = (
  onOk: () => void,
  title: string = 'Notification',
  description: string = 'Are you sure?',
  onCancel: () => void = () => {},
  okButtonText: string = 'Submit',
  cancelButtonText: string = 'Cancel',
) => {
  confirm({
    title: title,
    icon: <WarningOutlined />,
    content: description,
    okText: okButtonText,
    cancelText: cancelButtonText,
    onOk: () => onOk(),
    onCancel: () => onCancel(),
    maskTransitionName: '',
    transitionName: '',
  });
};
/**
 *
 * @param object
 * @returns new object with no property undefined or null
 */
export const cleanObject = <T extends Object>(object: T) => {
  const newObj: T = Object.assign({}, object);
  Object.keys(newObj).forEach((key: string) => {
    if (newObj[key] === null || newObj[key] === undefined) delete newObj[key];
  });
  return newObj;
};

export const auth = () => !!getCookie(TYPE_COOKIE.TOKEN);

/**
 * @param {number} input: number
 * @return number formatted, 100000 => 100,000
 */
export const formatNumber = (number: number) => {
  const value = number + '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
};

/**
 * @param func function debounced
 * @param waitFor time debounce
 */
export const debounce = <F extends (...args: any) => any>(
  func: F,
  waitFor: number,
) => {
  let timeout: number = 0;

  const debounced = (...args: any) => {
    clearTimeout(timeout);
    setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

/**
 * @return id random
 */
export const generateRandomID = () => nanoid();

/**
 *
 * @param length
 * @returns: string random has length
 */
export function generateStringRandom(length: number): string {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const addScriptDynamic = (src, onload?: () => void) => {
  let script = document.createElement('script');
  script.setAttribute('src', src);
  if (onload) script.onload = onload;
  document.head.appendChild(script);
};

export const toggleItemArrs = <T extends string | number>(
  arrs: T[],
  item: T,
) => {
  const listXor: T[] = arrs.some(el => el === item)
    ? arrs.filter(el => el !== item)
    : [...arrs, item];
  return listXor;
};

/**
 *
 * @param {*} arr1: []
 * @param {*} arr2" []
 * @param {*} field: indicated whether array of object or array of item
 * @returns list item difference arr1 compared to arr2
 */
export const differenceArrays = (arr1, arr2, field = 'id') =>
  arr1.filter(
    current =>
      !arr2.some(el => (field ? el[field] === current[field] : el === current)),
  );

export const onDisabledReactDevtoolOnProduction = () => {
  if (process.env.NODE_ENV === 'production') {
    // Ensure the React Developer Tools global hook exists
    const _window = window as any;
    if (typeof _window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'object') return;

    // Replace all global hook properties with a no-op function or a null value
    Object.keys(_window.__REACT_DEVTOOLS_GLOBAL_HOOK__).forEach(prop => {
      if (prop === 'renderers') {
        // prevents console error when dev tools try to iterate of renderers
        _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
      } else {
        _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
          typeof _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function'
            ? Function.prototype
            : null;
      }
    });
  }
};

export const openLink = (url?: string, insidePage: boolean = false) => {
  if (!insidePage) window.open(url, '_blank');
};

export const onFuncCheckShowDialogLogin = () => {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    const stateApp: unknown = state.app;
    if (stateApp) {
      if ((stateApp as AppState)?.isLogged) {
        return resolve(true);
      } else {
        store.dispatch(appActions.openDialogLogin());
        return reject(false);
      }
    }
  });
};

export const onFormatMoney = (money: number) =>
  money.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });

export const removeHTML = (str: string) => {
  let result = str || '';
  if (str) {
    result = result.replace(/\n/g, '<br />').replace(/<\/?[^>]+(>|$)/g, ' ');
  }
  return result;
};
