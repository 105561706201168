/**
 *
 * styled App
 *
 */
import styled from 'styled-components/macro';

export const AppWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
`;
