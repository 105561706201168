import {
  addItemToCartApi,
  getCartApi,
  removeItemToCartApi,
} from 'app/services/cartApi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_MY_CART, GET_MY_CART, REMOVE_MY_CART } from '.';

export function* getMyCart({ payload }) {
  try {
    const { data } = yield call(getCartApi, payload);
    yield put(GET_MY_CART.success(data.data));
  } catch {
    yield put(GET_MY_CART.failure());
  }
}

export function* addMyCart({ payload }) {
  try {
    const { data } = yield call(addItemToCartApi, payload);
    yield put(ADD_MY_CART.success(data.data));
  } catch {
    yield put(ADD_MY_CART.failure());
  }
}

export function* removeMyCart({ payload }) {
  try {
    const { data } = yield call(removeItemToCartApi, payload);
    yield put(REMOVE_MY_CART.success(data.data));
  } catch {
    yield put(REMOVE_MY_CART.failure());
  }
}

export function* cartSaga() {
  yield takeLatest(GET_MY_CART.TRIGGER, getMyCart);
  yield takeLatest(ADD_MY_CART.TRIGGER, addMyCart);
  yield takeLatest(REMOVE_MY_CART.TRIGGER, removeMyCart);
}
