import { logoutApi } from 'app/services/accountApi';
import { getLayoutApi, getMeApi } from 'app/services/appApi';
import {
  getListNotificationApi,
  seenAllNotificationApi,
} from 'app/services/notificationApi';
import { take, call, put, select, fork, takeLatest } from 'redux-saga/effects';
import { TYPE_COOKIE } from 'utils/constants';
import { getCookie, removeCookie } from 'utils/cookies';
import { clearAllLS } from 'utils/localStorage';
import {
  LOGIN,
  GET_ME,
  GET_LAYOUT,
  GET_NOTIFICATIONS,
  SEEN_NOTIFICATION,
  appActions as actions,
} from '.';

export function* watchAndLog() {
  while (true) {
    const styleConsole =
      'background: yellow; font-weight: bold; color: #40a9ff8c;';
    const action = yield take('*');
    const state = yield select();
    if (
      action.type !== '@@router/LOCATION_CHANGE' &&
      action.type !== 'app/updateMediaUploadingProgress' &&
      action.type !== 'reapop/upsertNotification' &&
      action.type !== 'reapop/dismissNotification'
    ) {
      console.group(action.type);
      console.log('%cBefore State', styleConsole, state);
      console.info('%cDispatching', styleConsole, action);
      console.log('%cNext State', styleConsole, state);
      console.groupEnd();
    }
  }
}

function* watchIsLogged() {
  while (true) {
    yield take(LOGIN.SUCCESS);
    yield put(actions.changeIsLogged({ isLogged: true }));
  }
}

export function* getMe() {
  try {
    const { data } = yield call(getMeApi);
    yield put(GET_ME.success(data));
  } catch {
    yield put(GET_ME.failure());
  }
}

export function* getLayout() {
  try {
    const response = yield call(getLayoutApi);
    yield put(GET_LAYOUT.success(response.data));
  } catch {
    yield put(GET_LAYOUT.failure());
  }
}

export function* getNotifications({ payload }) {
  try {
    const { data } = yield call(getListNotificationApi, payload);
    yield put(GET_NOTIFICATIONS.success(data.data));
  } catch {
    yield put(GET_NOTIFICATIONS.failure());
  }
}

export function* seenNotification({ payload }) {
  const formData = new FormData();
  if (!payload.isSeenAll && payload.idNotification) {
    formData.append('id', payload.idNotification);
  }
  try {
    yield call(seenAllNotificationApi, formData);
    yield put(SEEN_NOTIFICATION.success(payload));
  } catch {
    yield put(SEEN_NOTIFICATION.failure());
  }
}

function* logout() {
  const token = yield call(getCookie, TYPE_COOKIE.TOKEN);
  if (token) yield call(logoutApi);
  yield call(removeCookie, TYPE_COOKIE.TOKEN);
  yield call(clearAllLS);
  yield put(actions.changeIsLogged({ isLogged: false }));
}

export function* appSaga() {
  yield fork(watchIsLogged);
  yield takeLatest(GET_ME.TRIGGER, getMe);
  yield takeLatest(GET_LAYOUT.TRIGGER, getLayout);
  yield takeLatest(GET_NOTIFICATIONS.TRIGGER, getNotifications);
  yield takeLatest(SEEN_NOTIFICATION.TRIGGER, seenNotification);
  yield takeLatest(actions.logout.type, logout);
  if (process.env.NODE_ENV === 'development') yield fork(watchAndLog);
}
