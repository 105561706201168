import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { appSaga } from './saga';
import { AppState, I_Layout, I_Notification, I_User } from './types';

export const GET_ME = createRoutine('app/getMe');
export const GET_LAYOUT = createRoutine('app/getLayout');

export const LOGIN = createRoutine('app/login');
export const LOGOUT = createRoutine('app/logout');
export const REGISTER = createRoutine('app/register');
export const FORGET_PASSORD = createRoutine('app/forgetPassword');
export const RESET_PASSORD = createRoutine('app/resetPassword');
export const VERIFY_PASSORD = createRoutine('app/verifyPassword');
export const GET_NOTIFICATIONS = createRoutine('app/getNotifications');
export const SEEN_NOTIFICATION = createRoutine('app/seenNotification');

export const initialState: AppState = {
  loading: false,
  success: false,
  error: false,
  infoUser: undefined,
  isLogged: false,
  isShowDialogLogin: false,
  isShowDialogRegister: false,
  isShowDialogForgetPassword: false,
  notifications: [],
  layout: {
    header: {},
    footer: {},
  },
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openDialogLogin(state) {
      state.isShowDialogLogin = true;
      state.isShowDialogRegister = false;
      state.isShowDialogForgetPassword = false;
    },

    closeDialogAuth(state) {
      state.isShowDialogLogin = false;
      state.isShowDialogRegister = false;
      state.isShowDialogForgetPassword = false;
    },

    openDialogRegister(state) {
      state.isShowDialogLogin = false;
      state.isShowDialogRegister = true;
      state.isShowDialogForgetPassword = false;
    },

    openDialogForgetPassword(state) {
      state.isShowDialogForgetPassword = true;
      state.isShowDialogRegister = false;
      state.isShowDialogLogin = false;
    },

    changeIsLogged(state, action: PayloadAction<{ isLogged: boolean }>) {
      state.isLogged = action.payload.isLogged;
    },

    changeStateLoading(state, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading;
    },

    setInfoUser(state, action: PayloadAction<I_User | undefined>) {
      state.infoUser = state.infoUser
        ? { ...state.infoUser, ...action.payload }
        : action.payload;
    },

    logout(state) {
      state.infoUser = initialState.infoUser;
      state = initialState;
    },

    resetState(state) {
      Object.keys(state).forEach(item => {
        state[item] = initialState[item];
      });
    },
  },
  extraReducers: {
    /**
     * !GET_LAYOUT
     */
    [GET_LAYOUT.TRIGGER]: state => {
      state.loading = true;
      // state.success = false;
      state.error = false;
    },
    [GET_LAYOUT.SUCCESS]: (state, action: PayloadAction<I_Layout>) => {
      state.layout = action.payload;
      state.loading = false;
      // state.success = true;
      state.error = false;
    },
    [GET_LAYOUT.FAILURE]: state => {
      state.loading = false;
      // state.success = false;
      state.error = true;
    },
    /**
     * !GET_ME
     */
    [GET_ME.TRIGGER]: state => {
      state.loading = true;
      // state.success = false;
      state.error = false;
    },
    [GET_ME.SUCCESS]: (state, action: PayloadAction<I_User>) => {
      state.infoUser = action.payload;
      state.loading = false;
      // state.success = true;
      state.error = false;
    },
    [GET_ME.FAILURE]: state => {
      state.loading = false;
      // state.success = false;
      state.error = true;
    },
    /**
     * !GET_NOTIFICATIONS
     */
    [GET_NOTIFICATIONS.TRIGGER]: state => {},
    [GET_NOTIFICATIONS.SUCCESS]: (
      state,
      action: PayloadAction<I_Notification[]>,
    ) => {
      state.notifications = action.payload;
    },
    [GET_NOTIFICATIONS.FAILURE]: state => {},
    /**
     * !SEEN_NOTIFICATION
     */
    [SEEN_NOTIFICATION.TRIGGER]: state => {
      state.loading = true;
      // state.success = false;
      state.error = false;
    },
    [SEEN_NOTIFICATION.SUCCESS]: (
      state,
      action: PayloadAction<{ isSeenAll: boolean; idNotification?: number }>,
    ) => {
      const { isSeenAll, idNotification } = action.payload;
      if (isSeenAll)
        state.notifications = state.notifications.map(item => ({
          ...item,
          seen: true,
        }));
      if (!isSeenAll && idNotification)
        state.notifications = state.notifications.map(item =>
          item.id === idNotification
            ? {
                ...item,
                seen: true,
              }
            : item,
        );
      state.loading = false;
      // state.success = true;
      state.error = false;
    },
    [SEEN_NOTIFICATION.FAILURE]: state => {
      state.loading = false;
      // state.success = false;
      state.error = true;
    },
  },
});

export const { actions: appActions } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: appSaga });
  return { actions: slice.actions };
};
