import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { TYPE_LOCAL_STORAGE } from 'utils/constants';
import { auth } from 'utils/helper';
import { setItem } from 'utils/localStorage';
import { LIST_PUBLIC_ROUTE } from './constants';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={props => {
        if (auth()) return <Component {...props} />;
        else {
          let pathname = history.location.pathname;
          if (!LIST_PUBLIC_ROUTE.some(item => pathname.includes(item)))
            setItem(TYPE_LOCAL_STORAGE.URL_REDIRECT, pathname);
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }
      }}
    />
  );
};
